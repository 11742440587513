import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from '../styles/theme';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { CookieInfo } from './CookieInfo/CookieInfo';
import '../styles/scss/styles.scss';

interface IProps {
  children: React.ReactNode;
  // eslint-disable-next-line react/require-default-props
  isPost?: boolean;
}

export const Layout = ({ children, isPost = false }: IProps) => (
  <ThemeProvider theme={theme}>
    <div>
      <Helmet />
      <GlobalStyle />
      <Header isPost={isPost} />
      {children}
      <Footer />
      <CookieInfo />
    </div>
  </ThemeProvider>
);
