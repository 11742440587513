import React from 'react';
import { Link } from 'gatsby';
import logo from '../../img/logo-color-1.png';

export const Footer: React.FC = () => (
  <footer>
    <section className="section section--sitemap">
      <div className="wrapper">
        <div className="sitemap">
          <div className="sitemap__links-container">
            <div className="sitemap__link-block">
              <h3 className="sitemap__title">Świat Ubezpieczeń</h3>
              <Link to="/o-nas/" className="sitemap__link">O nas</Link>
              <Link to="/blog/" className="sitemap__link">Blog</Link>
            </div>
            <div className="sitemap__link-block">
              <h3 className="sitemap__title">Kalkulator</h3>
              <a href="https://porowneo.pl/ubezpieczenia/samochodu/ubezpieczenie-oc" rel="noopener noreferrer" target="_blank" className="sitemap__link">Kalkulator OC/AC</a>
            </div>
            <div className="sitemap__link-block">
              <h3 className="sitemap__title">Partnerzy</h3>
              <Link to="/o-ubezpieczeniach/" className="sitemap__link">Towarzystwa</Link>
            </div>
            <div className="sitemap__link-block">
              <h3 className="sitemap__title">Linki</h3>
              <Link to="/polityka-prywatnosci/" className="sitemap__link">Polityka prywatności</Link>
            </div>
          </div>
          <Link to="/" className="sitemap__logo-link">
            <img src={logo} alt="logo" className="sitemap__logo" style={{ width: '162px', height: '28px' }} />
          </Link>
        </div>
      </div>
    </section>
    <section className="section section--black section--reset">
      <div className="wrapper">
        <div className="copyright">
          <div className="copyright__title">Wszelkie prawa zastrzeżone: Porowneo.pl Sp. z o.o.</div>
        </div>
      </div>
    </section>
  </footer>
);
