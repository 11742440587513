import styled, { css, createGlobalStyle } from 'styled-components';

export { css, styled };

export const theme = {
  colors: {
    black: '#000000',
    contrast: '#111',
    contrastLightest: '#dad9d9',
    accent: 'red',
    white: '#ffffff',
    paragraph: '#5A6175',
    background: '#ffffff',
    backgroundLight: '#F1F6FF',
  },
};

const reset = () => `
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  margin: 0 !important;
  padding: 0;
}

::selection {
  background-color: ${theme.colors.contrastLightest};
  color: rgba(0, 0, 0, 0.70);
}

a.anchor, a.anchor:hover, a.anchor:link {
  background: none !important;
}

figure {
  a.gatsby-resp-image-link {
    background: none;
  }

  span.gatsby-resp-image-wrapper {
    max-width: 100% !important;
  }
}
`;

const styles = () => `
  html, body, p, ul, li, figure, legend, h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }
  
  html {
    box-sizing: border-box;
    background-color: white;
    font-size: 16px;
    min-width: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
  }
  *, *::before, *::after {
      box-sizing: inherit;
  }

  img {
    vertical-align: top;
    max-width: 100%;
    height: auto;
  }

`;

export const GlobalStyle = createGlobalStyle`
${reset()}
${styles()}
`;
