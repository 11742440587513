import React, { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import { Link } from 'gatsby';
import './style.scss';

export function CookieInfo() {
  const [isVisible, setVisible] = useState<boolean>(false);
  const cookiePolicy = 'cookie-policy';
  const cookiePolicyValue = '1';
  const sessionStorageClosedKey = 'closed-cookie-bar';
  const sessionStorageClosedValue = '1';
  const cookieDaysExpires = 365;
  const date = new Date();
  date.setTime(date.getTime() + (cookieDaysExpires * 24 * 60 * 60 * 1000));
  const cookieNameValue = `${cookiePolicy}=${cookiePolicyValue}`;
  const cookieExpiresTime = `expires=${date.toUTCString()}`;
  const barTransitionDuration = 400;
  const defaultTransitionStyle = {
    transition: `opacity ${barTransitionDuration}ms ease-in-out`,
    opacity: 0,
  };
  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 },
  };

  const isCookieSet = (cookieNameToCheck: string, cookieValueToCheck: string): boolean => {
    if (document.cookie !== '') {
      const cookies = document.cookie.split(/; */);

      for (let i = 0; i < cookies.length; i += 1) {
        const [cookieName, cookieVal] = cookies[i].split('=');
        if ((cookieName === decodeURIComponent(cookieNameToCheck) && decodeURIComponent(cookieVal) === cookieValueToCheck)) {
          return true;
        }
      }
      return false;
    }
    return false;
  };

  const showCookieInfo = (isAccepted: boolean): void => {
    const isClosedSetInSessionStorage = sessionStorage.getItem(sessionStorageClosedKey);
    if ((document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) && !isAccepted && !isClosedSetInSessionStorage) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const removeCookiePolicyBar = () => {
    sessionStorage.setItem(sessionStorageClosedKey, sessionStorageClosedValue);
    setVisible(false);
  };

  const acceptCookiePolicy = () => {
    removeCookiePolicyBar();
    sessionStorage.removeItem(sessionStorageClosedKey);
    document.cookie = `${cookieNameValue}; ${cookieExpiresTime}`;
  };

  const scrollListener = () => showCookieInfo(isCookieSet(cookiePolicy, cookiePolicyValue));

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  });

  return (
    <Transition in={isVisible} timeout={barTransitionDuration}>
      {state => (
        <div style={{
          ...defaultTransitionStyle,
          ...transitionStyles[state],
        }}
        >
          <div className="cookie-bar">
            <div className="row row--col-reverse row--flex">
              <p className="cookie-bar__info-text row__large-8">
                {/* eslint-disable-next-line max-len */}
                Korzystanie z witryny oznacza zgodę na wykorzystanie plików cookie. Niektóre z nich mogą być już zapisane w folderze przeglądarki. Więcej informacji możesz znaleźć w
                {' '}
                <Link to="/polityka-prywatnosci/" className="cookie-bar__link">polityce prywatności i cookies.</Link>
              </p>
              <div className="cookie-bar__accept-cookies-wrapper">
                <button onClick={acceptCookiePolicy} className="cookie-bar__accept-cookies-button" type="button">Zgadzam się</button>
              </div>
              <button onClick={removeCookiePolicyBar} type="button" className="cookie-bar__close">x</button>
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
}
