import React, { useState } from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import logoColor from '../../img/logo-color.png';
import Navigation from './Navigation';

export const Header: React.FC<{isPost?: boolean}> = ({ isPost }) => {
  const [isOpen, setOpened] = useState<boolean>(false);
  const toggle = () => setOpened(!isOpen);

  const headerStyles = classnames('section section--header header', {
    'header--open': isOpen,
    'header--isPost': isPost,
  });

  const menuStyles = classnames('header__nav', {
    'header__nav--open': isOpen,
  });

  const toggleIconStyles = classnames('header__toggle-icon', {
    'header__toggle-icon--open': isOpen,
  });

  const buttonLinkStyles = classnames('btn btn--primary btn--link header__button-link', {
    'header__button-link--open': isOpen,
  });

  return (
    <header className={headerStyles}>
      <div className="wrapper">
        <div className="header__container">
          <Link to="/"><img src={logoColor} alt="logo" className="header__logo" style={{ width: '222px', height: '39px' }} /></Link>
          <div className="header__nav-container">
            <div className="header__desktop-menu"><Navigation desktop /></div>
            <a href="https://porowneo.pl/ubezpieczenia/samochodu/ubezpieczenie-oc" rel="noopener noreferrer" target="_blank" className={buttonLinkStyles}>Porównaj ceny</a>
            <button
              className={toggleIconStyles}
              onClick={toggle}
              aria-label="Toggle menu"
            >
              <span className="header__toggle-icon-span" />
              <span className="header__toggle-icon-span" />
              <span className="header__toggle-icon-span" />
            </button>
          </div>
        </div>
      </div>
      <div className={menuStyles}>
        <div className="wrapper">
          {isOpen && <Navigation />}
        </div>
      </div>
    </header>
  );
};
