/* eslint-disable import/no-default-export */
import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';

const config = [
  { title: 'O nas', path: '/o-nas' },
  { title: 'Towarzystwa', path: '/o-ubezpieczeniach' },
  { title: 'Blog', path: '/blog' },
];

const Navigation: React.FC<{ desktop?: boolean }> = ({ desktop }) => {
  const menuStyles = classnames('menu', {
    'menu--desktop': desktop,
  });
  const itemStyles = classnames('menu__item', {
    'menu__item--desktop': desktop,
  });
  const linkStyles = classnames('menu__link', {
    'menu__link--desktop': desktop,
  });
  return (
    <ul className={menuStyles}>
      {config.map(item => (
        <li className={itemStyles} key={item.title}>
          <Link to={`${item.path}/`} activeClassName="menu__link--active" className={linkStyles}>
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Navigation;
